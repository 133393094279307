$(document).ready(function () {
    const video = $('#myVideo');
        if(video.length){
        const playIcon = $('.play-btn');
        const pauseIcon = $('.pause-btn');
        const volumeIcon = $('.mute-this');
        const muteIcon = $('.unmute-this');
        const seekbar = $('.seekbar');
        const seeker = $('.seeker');
        const fullscreenBtn = $('.fullscreenBtn');
        const goFullscreenIcon = $('.goFullscreen');
        const exitFullscreenIcon = $('.exitFullscreen');
        const videoContainer = $('#video_player');

        // Play/Pause Toggle
        $('.playPauseBtn').click(function () {
            if (video[0].paused) {
                video[0].play();
                playIcon.hide();
                pauseIcon.show();
            } else {
                video[0].pause();
                playIcon.show();
                pauseIcon.hide();
            }
        });

        video.click(function () {
            if (video[0].paused) {
                video[0].play();
                playIcon.hide();
                pauseIcon.show();
            } else {
                video[0].pause();
                playIcon.show();
                pauseIcon.hide();
            }
        });

        // Mute/Unmute Toggle
        $('.volumeBtn').click(function () {
            video[0].muted = !video[0].muted;
            if (video[0].muted) {
                volumeIcon.hide();
                muteIcon.show();
            } else {
                volumeIcon.show();
                muteIcon.hide();
            }
        });

        // Update custom seekbar as video plays
        video[0].ontimeupdate = function () {
            const progress = (video[0].currentTime / video[0].duration) * 100;
            seeker.css('width', progress + '%');
        };

        // Seek on click
        seekbar.click(function (e) {
            const offsetX = e.pageX - $(this).offset().left;
            const width = $(this).width();
            const percent = offsetX / width;
            video[0].currentTime = percent * video[0].duration;
        });

        // Seek on drag
        let isDragging = false;

        seekbar.on('mousedown', function (e) {
            isDragging = true;
            updateSeek(e);
        });

        $(document).on('mousemove', function (e) {
            if (isDragging) {
                updateSeek(e);
            }
        }).on('mouseup', function () {
            isDragging = false;
        });

        function updateSeek(e) {
            const offsetX = e.pageX - seekbar.offset().left;
            const width = seekbar.width();
            const percent = Math.max(0, Math.min(1, offsetX / width));
            seeker.css('width', percent * 100 + '%');
            video.currentTime = percent * video.duration;
        }

        // Fullscreen Toggle
        fullscreenBtn.click(function () {
            if (!document.fullscreenElement) {
                videoContainer[0].requestFullscreen();
                goFullscreenIcon.hide();
                exitFullscreenIcon.show();
            } else {
                document.exitFullscreen();
                goFullscreenIcon.show();
                exitFullscreenIcon.hide();
            }
        });

        // Exit fullscreen if user exits it with ESC key or other methods
        $(document).on('fullscreenchange', function () {
            if (!document.fullscreenElement) {
                goFullscreenIcon.show();
                exitFullscreenIcon.hide();
            }
        });
    }

    $('.js-play-video').click(function (e) {
        e.preventDefault();
        const video_url = $(this).data('play-url');
        const videoCard = $(this).closest('.js-video_card')
        const title = videoCard.find('.video_title').text();
        const duration = videoCard.find('.video_duration').text();
        
        videoContainer.find('.video_container video source').attr('src', video_url);
        
        video[0].load();

        videoContainer.find('.details .video-title').html(title);
        videoContainer.find('.details .video-time').html(duration);

        $('.video_player').addClass('open');

    })

    $('.js-close-player').click(function (e) {
        e.preventDefault();

        $('.video_player').removeClass('open');

        video[0].pause();
        playIcon.show();
        pauseIcon.hide();

        video[0].muted = false;
        volumeIcon.show();
        muteIcon.hide();
        
        if (document.fullscreenElement) {document.exitFullscreen()}
        goFullscreenIcon.show();
        exitFullscreenIcon.hide();
        
        setTimeout(() => {
            seeker.css('width', '0%');
        }, 200);
    })
});
