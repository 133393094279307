const showToast = (content) => {
    $('#toast-container .toast').remove();

    const toastMessage = $('<div class="toast">');
    const toast = $('<div class="toast-content">').html(content);

    const closeButton = 
        $(`<div class="toast-close">
             <img src="/static/svg/close.svg" alt="">
            </div>
        `).on("click", function() {
            toastMessage.remove();
        });


    toastMessage.append(toast);
    toastMessage.append(closeButton);

    $('#toast-container').append(toastMessage);

    setTimeout(function() {
        toastMessage.fadeOut(300, function() {
            $(this).remove();
        });
    }, 5000);
}