$( document ).ready(function() {   
    let countryId, stateId;
    $('.select-type').select2({placeholder: "Select Type"});
    $('.select-country').select2({placeholder: "Select Country"});
    $('.select-area').select2({placeholder: "Select Area"});
    $('.select-city').select2({placeholder: "Select City"});


    // API Call Requests
    const GetStates = (country_id, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: `/en/api/address/states/?country=${country_id}`,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }
    const GetCities = (state_id, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: `/en/api/address/cities/?state=${state_id}`,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }
    const PostNewAddress = (data, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: `/en/api/address/add/`,
            headers: {
                'X-CSRFToken': csrf_token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data),
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }
    const GetAddress = (address_id, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: `/en/api/address/?address_id=${address_id}`,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }
    const PutUpdateAddress = (address_id, data, doneCallback) => {
        $.ajax({
            method: 'PUT',
            url: `/en/api/address/?address_id=${address_id}`,
            headers: {
                'X-CSRFToken': csrf_token,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data),
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }

    //init International Telephone Input
    $(".phone").each(function() { 
        const input = this; 
        setTimeout(function(){
            const intl = window.intlTelInput(input, {
                // onlyCountries: ["gb"],           
                initialCountry: "gb",            
                separateDialCode: true,         
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.7.0/build/js/utils.js"
            });
            $(input).data('intl', intl);
        }, 300)

    });


    // Event listener for country selection
    $('#id_country').on('change', function () {
        countryId = $(this).val();

        GetStates(countryId, function (states) {
            $('#id_state').empty().append('<option disabled selected>Select Area</option>');
            states.forEach(state => {
                $('#id_state').append(new Option(state.name, state.id));
            });
            
            $('#id_state').prop('disabled', false);
        });
    });

    $('#id_state').on('change', function () {
        stateId = $(this).val();

        GetCities(stateId, function (cities) {
            $('#id_line4').empty().append('<option disabled selected>Select City</option>');
            cities.forEach(city => {
                $('#id_line4').append(new Option(city.name, city.id));
            });
            
            $('#id_line4').prop('disabled', false);
        });
    });

    //form field validator
    const validateForm = (form) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;    
        let isValid = true; 
    
        // Reset all borders and error message
        form.find('input').css("border", ""); 
        form.find('.error-message').remove();
    
        // Find and highlight empty fields
        const emptyFields = form.find('input[type="text"]').not('#id_phone_number, .iti__search-input').filter(function() {
            return $(this).val().trim() === "";
        });
        if (emptyFields.length > 0) {
            emptyFields.css("border", "1px solid red");
            isValid = false;
        }
    
        // Validate email field
        const emailField = form.find('input[name="email"]');
        if (emailField.length && !emailRegex.test(emailField.val().trim())) {
            emailField.css("border", "1px solid red");
            isValid = false;
        }
    
        // Validate phone number field (intl-tel-input)
        const phoneField = form.find('input[name="phone_number"]');
        if (phoneField.length) {
            const telInst = phoneField.data('intl');
            if (telInst && !telInst.isValidNumber()) {              
                phoneField.css("border", "1px solid red");
                isValid = false;
            }
        }       

        // Validate select fields
        const selectFields = form.find('select');
        selectFields.each(function() {
            const selectedValue = $(this).val();
            if (!selectedValue || selectedValue === "default") {
                $(this).next('.select2-container').css("border", "1px solid red"); 
                isValid = false;
            } else {
                $(this).next('.select2-container').css("border", "");
            }            
        });

        // Validate textarea fields
        const textAreas = form.find('textarea').not('#address_notes');
        textAreas.each(function() {
            const textValue = $(this).val().trim();
            if (!textValue) {
                $(this).css("border", "1px solid red"); 
                isValid = false;
            } else {
                $(this).css("border", ""); 
            }
        });
    
        // Display error message if form is invalid
        if (!isValid) {
            if (!form.find('.error-message').length) {
                showToast(`
                    <h4>Form validation error!</h4>
                    <p>Fill out all required fields correctly.</p>
                `);
            }
        }
    
        return isValid;
    };    
    
    // Populate the edit address form using address ID
    if($('body').hasClass('account') && $('.account_forms.address_form').length > 0){
        const address_id = $('.delivery_address-form').data('address-id');

        if (address_id && address_id !== 'new') {
            GetAddress(address_id, function(data) {
                // Populate the form fields with the address data
                $('#id_first_name').val(data.first_name);
                $('#id_last_name').val(data.last_name);
                $('#id_line1').val(data.line1);
                $('#id_line2').val(data.line2);
                $('#id_postcode').val(data.postcode);
                $('#id_phone_number').val(data.phone_number);
                $('#address_notes').val(data.notes);
                $('#id_tag').val(data.tag).trigger('change');   
                
                $('#id_country').val(data.country_id).trigger('change'); 

                
                setTimeout(function() {
                    GetStates(data.country_id, function(states) {                
                        $('#id_state').empty().append('<option disabled selected>Select Area</option>');
                        states.forEach(state => {
                            $('#id_state').append(new Option(state.name, state.id));
                        });
                        $('#id_state').val(data.state_id).trigger('change');  
                        $('#id_state').prop('disabled', false); 

                        setTimeout(function() {
                            GetCities(data.state_id, function(cities) {
                                $('#id_line4').empty().append('<option disabled selected>Select City</option>');
                                cities.forEach(city => {
                                    $('#id_line4').append(new Option(city.name, city.id));
                                });
                                    
                                $('#id_line4').val(data.line4_id).trigger('change');
                                $('#id_line4').prop('disabled', false); 
                            });
                        }, 300);
                    });
                }, 200);
            });
        }
    }

    //helper function to add address to address book in checkout
    const addAddressToList = (address) => {
        const AddressHTML = `
            <div class="address">
                <input type="radio" name="shipping-address" id="address-${address.id}" value="${address.id}" checked="true">
                <label for="address-${address.id}">  
                    <div class="left">
                        <div class="selector">
                            <div class="circle">
                                <div class="round"></div>
                            </div>
                        </div>
                        <div class="details">
                            <span class="line1">${address.tag} | ${address.line1}</span>
                            <span class="line2">${address.line2}, ${address.line4}, ${address.state}, ${address.postcode}</span>
                        </div>
                    </div>
                </label>  
            </div>
        `
        $('.user_details_change .Addresses > .address').find('input[type="radio"]').each(function() {
            $(this).prop('checked', false);  
        });
        $('.user_details_change .Addresses').prepend(AddressHTML);
    }

    $('.js-save-new-address').on('click', function(e) {
        e.preventDefault();        
        const form = $(this).closest('form.update_delivery_address');
        if (validateForm(form)) {
            const newAddress = {
                tag: $('#id_tag').val(),
                first_name: $('#id_first_name').val(),
                last_name: $('#id_last_name').val(),
                line1: $('#id_line1').val(),
                line2: $('#id_line2').val(),
                line3: '',  
                line4_id: parseInt($('#id_line4').val()) || 0,  
                state_id: parseInt($('#id_state').val()) || 0,
                postcode: $('#id_postcode').val(),
                country_id: parseInt($('#id_country').val()) || 0,
                phone_number: form.find('.phone').data('intl').getNumber(),
                notes: $('#address_notes').val()
            };
            PostNewAddress(newAddress, function(response) {                
                form[0].reset();
                $('.select-type, .select-country, .select-area, .select-city').val('').trigger('change');    
                form.find('#id_state, #id_line4').prop('disabled', true);

                addAddressToList(response);   
                $('#user-address').show();
                $('#user-address-form').hide();

                showToast(`
                    <h4>Address</h4>
                    <p>Saved successfully!</p>
                `);
            });
        }       
        
    });


    //Helper function to execute after address save
    const addressFormSubmit = (action, form) => {
        form[0].reset();
        $('.select-type, .select-country, .select-area, .select-city').val('').trigger('change');    
        form.find('#id_state, #id_line4').prop('disabled', true);

        showToast(`
            <h4>Address</h4>
            <p>${action === 'edit' ? 'Updated' : 'saved'} successfully!</p>
        `);
        setTimeout(function() {
            window.location.href = '/en/accounts/addresses';
        }, 500);
    }

    $('.js-submit-address').on('click', function(e) {
        e.preventDefault();        
        const form = $(this).closest('form.delivery_address-form');
        const action = $(this).data('address-action');
        const adddressId = action === 'edit' ? form.data('address-id') : null;
        if (validateForm(form)) {
            const addressData = {
                tag: $('#id_tag').val(),
                first_name: $('#id_first_name').val(),
                last_name: $('#id_last_name').val(),
                line1: $('#id_line1').val(),
                line2: $('#id_line2').val(),
                line3: '',  
                line4_id: parseInt($('#id_line4').val()) || 0,  
                state_id: parseInt($('#id_state').val()) || 0,
                postcode: $('#id_postcode').val(),
                country_id: parseInt($('#id_country').val()) || 0,
                phone_number: form.find('.phone').data('intl').getNumber(),
                notes: $('#address_notes').val()
            };


            if(action === 'edit'){
                PutUpdateAddress(adddressId, addressData, function() {                
                    addressFormSubmit('edit', form);
                });
            }else if(action === 'new'){
                PostNewAddress(addressData, function() { 
                    addressFormSubmit('new', form);
                })
            }
        }       
        
    });
})