$( document ).ready(function() {
    //get the children product data
    let childern_products = [];
    let selected_child = {};
    let basketLineCount;
    const basketLineCountHolder = $('#js-basket-line-count');

    // API Call Requests
    const PostAddToBasket = (product_id, doneCallback, plan) => {
        let url = `/en/api/basket/add/${product_id}`;
        if (plan) {
            url += `?plan=${plan}`;
        }
        $.ajax({
            method: 'POST',
            url: url,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostRemoveFromBasket = (product_id, doneCallback, plan) => {        
        let url = `/en/api/basket/remove/${product_id}`;
        if (plan) {
            url += `?plan=${plan}`;
        }
        $.ajax({
            method: 'POST',
            url: url,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const GetProductChildData = (product_id, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: '/en/api/catalogue/' + product_id,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const GetTimeSlots = (doneCallback) => {
        $.ajax({
            method: 'GET',
            url: '/en/api/checkout/timeslots/',
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostCheckoutSetTimeslot = (slot_id, date, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/checkout/timeslots/set/?slot_id=' + slot_id + '&date=' + date,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const GetSubscriptionProductPrices = (product_id, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: `/en/api/catalogue/${product_id}/subscription-prices` ,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostSubscriptionPause = (subscription_id,  doneCallback) => {
        $.ajax({
            method: 'POST',
            url: `/en/api/subscription/pause?subscription_id=${subscription_id}` ,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostSubscriptionResume = (subscription_id,  doneCallback) => {
        $.ajax({
            method: 'POST',
            url: `/en/api/subscription/resume?subscription_id=${subscription_id}` ,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostSubscriptionEnd = (subscription_id,  doneCallback) => {
        $.ajax({
            method: 'POST',
            url: `/en/api/subscription/end?subscription_id=${subscription_id}` ,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }

    //Append product to basket
    const addProductToCart = (data) => {
        let productHTML = `
            <div class="product" data-basket-product-id="${data.product_id}" data-basket-sub-id="${data.sub_id}">
                <div class="products_image">
                    <div class="image_wrapper">
                        <img src="${data.product_img}" alt="${data.product_title}">
                    </div>
                </div>
                <div class="product_info">
                    <h4 class="product_title">${data.product_title}</h4>
                    <p class="product_details">${data.product_size}</p>
                    ${data.sub_id > 0 ? (`<p class="product_details">Subscription Plan: ${data.sub_plan}</p>`) : "" }
                    <p class="product_cost">
                        <span class="currency">${data.basket_currency}</span>
                        <span class="line_price">${data.product_cost}</span>
                    </p>
                </div>
                <div class="cart_action">
                    <div class="product_qty">
                        <div class="left">
                            <input type="number" value="1" class="qty_of_prd" min="1" max="99" readonly>
                        </div>
                        <div class="right">
                            <a href="javascript:void(0)" class="js_increase_product_qty" data-product-id="${data.product_id}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
                                    <path id="Polygon_95" data-name="Polygon 95" d="M4,0,8,6H0Z" fill="#969191"></path>
                                </svg>
                            </a>
                            <a href="javascript:void(0)" class="js_reduce_product_qty" data-product-id="${data.product_id}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
                                    <path id="Polygon_91" data-name="Polygon 91" d="M4,0,8,6H0Z" transform="translate(8 6) rotate(180)" fill="#969191"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        `;
        $('.cart .cart_sidebar .middleContent .store .product_list').append(productHTML);
    }
    //Swicth child product data in product details page
    const switchChildrenData = (id, children) => {
        children.forEach(function (child) {
            if (child.pk == id) {
                selected_child = child;
                $('.product_details .details_card .product_price .cost').html(selected_child?.price);
                $('.product_details .cart_action .product_qty input').val(selected_child?.quantity);
                $('.product_details .cart_action .js-add-product-to-cart').attr('data-product-id', selected_child?.pk);
                $('.product_details .cart_action .js-increase-prd-qty').attr('data-product-id', selected_child?.pk);
                $('.product_details .cart_action .js-decrease-prd-qty').attr('data-product-id', selected_child?.pk);

                return false; 
            }
        });
    }

    //On page load get the child product data
    if ($('.js-invoke-child-data').length) {
        const product_id = $('.js-add-product-to-cart').data('product-id');

        GetProductChildData(product_id, function (data) {
            childern_products = data;    
            
            const dataChildId = $('.js-invoke-child-data > .product_sizes > .size:first').find('label').data('child-id');
            switchChildrenData(dataChildId, childern_products);
        });
    };

    //invoke swicthing child product data on click
    $('.js-switch-size-data').on('click', function() {
        const dataChildId = $(this).data('child-id');
        switchChildrenData(dataChildId, childern_products);
        $('.product_details .details_card .product_qty span:first').html($(this).find('span').html())
    });

    // Helper function to extract product data
    const getProductData = (product) => {
        return {
            product_id: product.find('.product_sizes').length > 0 ? product.find('.product_sizes input:checked + label').data('child-id') : product.find('.js-add-product-to-cart').data('product-id'),
            product_img: product.find('.left .product_images .slider .swiper-slide:first img').attr('src'),
            product_title: product.find('.right .product_details .details_card .product_title').html(),
            product_size: product.find('.right .product_details .details_card .product_qty span:first').html(),
            product_cost: product.find('.right .product_details .details_card .product_price .cost').html(),
            basket_currency: product.find('.right .product_details .details_card .product_price .currency').html()
        };
    }

    //Helper function to udapte cart summary
    const updateCartSummary = (product_cost, action) => {
        const cart_sub_total = $('.cart .cart_summary .sub_total .sub_total_value');
        const basket_summary = $('.order_summary.js-basket-summar .order_details ul .sub_total .sub_total_value');
        let cart_sub_total_val = Number(cart_sub_total.html().replace(/,/g, ''));
        let updated_cart_sub_total_val =  action === 'increase' ? (cart_sub_total_val + Number(product_cost)).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (cart_sub_total_val - Number(product_cost)).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        cart_sub_total.html(updated_cart_sub_total_val);
        basket_summary.html(updated_cart_sub_total_val);
    }

    //Helper function to update line price
    const updateLinePrice = (ProductData, current_line_qty, basket_line, action) => {
        let newPrice = action === 'increase' ? ProductData.product_cost * (current_line_qty + 1) : ProductData.product_cost * (current_line_qty - 1);
        newPrice = newPrice.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        basket_line.find('.product_info .product_cost .line_price').html(newPrice);
    }

    //Helper function to update product removed from cart status
    const productCartStatus = (productID) => {
        $('.featured_product_slider').length > 0 && $('.featured_product_slider').each(function() {
            $(this).find('.product_card').each(function() {
                if ($(this).data('product-id') === productID) {
                    $(this).removeClass('prd_in_cart');
                }
            });
        });
        $('.productslider').length > 0 && $('.productslider').each(function() {
            $(this).find('.product_card').each(function() {
                if ($(this).data('product-id') === productID) {
                    $(this).removeClass('prd_in_cart');
                }
            });
        });
        $('.article_products_block').length > 0 && $('.article_products_block').each(function() {
            $(this).find('.product_card_mini').each(function() {
                if ($(this).data('product-id') === productID) {
                    $(this).removeClass('prd_in_cart');
                }
            });
        });
        $('.ingredients_block').length > 0 && $('.ingredients_block').each(function() {
            $(this).find('.product_card_mini').each(function() {
                if ($(this).data('product-id') === productID) {
                    $(this).removeClass('prd_in_cart');
                }
            });
        });
        $('.product_archive').length > 0 && $('.product_archive').find('.product_card').each(function() {
            if ($(this).data('product-id') === productID) {
                $(this).removeClass('prd_in_cart');
            }
        });
        $('.cart .extra_products').length > 0 && $('.cart .extra_products').find('.product_card_mini').each(function() {
            if ($(this).data('product-id') === productID) {
                $(this).removeClass('prd_in_cart');
            }
        });
        $('.searchBar_wrapper').length > 0 && $('.searchBar_wrapper').find('.product_card_mini').each(function() {
            if ($(this).data('product-id') === productID) {
                $(this).removeClass('prd_in_cart');
            }
        });
        $('.wishlist_products').find('.product_card_mini').each(function() {
            if ($(this).data('product-id') === productID) {
                $(this).removeClass('prd_in_cart');
            }
        });
    }

    //Helper function to update basket count
    const updateBasketCount = (action) => {        
        basketLineCount = Number(basketLineCountHolder.html());
        action === 'increase' ? basketLineCountHolder.html(basketLineCount + 1) : basketLineCountHolder.html(basketLineCount - 1);
    }
        

    //Helper function to get subscription product rates
    let subscriptionPrices;
    const fetchSubscriptionPrices = () => {
        if($('.subscription_option').length === 0) return
        const product_id = $('.add-to-cart.js-add-product-to-cart').attr('data-product-id');
        GetSubscriptionProductPrices(product_id, function(data) {
            subscriptionPrices = data;
            $('#sub-discounted-price').html(subscriptionPrices[0]?.price);
            $('#data-plan').attr('data-sub-plan', subscriptionPrices?.[0]?.name);
            $('#sub-discount-rate').html(`${subscriptionPrices[0]?.percentage}%`);
        });
    };
    
    // Invoke above function 
    fetchSubscriptionPrices();

    //helper function to update the subscription plan details
    const updateSubscriptionDetails = (selectedPk) => {
        const selectedPlan = subscriptionPrices && subscriptionPrices.find(plan => plan.pk === selectedPk);
        if (selectedPlan) {
            $('#sub-discounted-price').html(selectedPlan.price);
            $('#data-plan').attr('data-sub-plan', selectedPlan.name);
            $('#sub-discount-rate').html(`${selectedPlan.percentage}%`);
        }
    };
    //invole subscription plan change
    $('#sub_options').on('change', function () {
        const selectedPk = parseInt($(this).val(), 10);
        updateSubscriptionDetails(selectedPk);
                  
        const basket_lines = $('.cart .cart_sidebar .middleContent .store .product_list');
        const product_id = $('.js-add-product-to-cart').data('product-id');
        const sub_line = basket_lines.find(`[data-basket-product-id="${product_id}"][data-basket-sub-id="${selectedPk}"]`);

        if (sub_line.length > 0) {
            const basket_qty = sub_line.find(".cart_action .product_qty .qty_of_prd").val();    
            $('.product_hero .cart_action .product_qty .qty_of_prd').val(basket_qty);
        } else {
            $('.product_hero .cart_action .product_qty .qty_of_prd').val(0);
        }
    });

    //helper function to update the product qty in basket when switching between subscription modes
    $('.subscription_mode .subscription_option label').on('click', function () {
        const product_id = $('.js-add-product-to-cart').data('product-id');
        const basket_lines = $('.cart .cart_sidebar .middleContent .store .product_list');
        const subscription = $('input[name="subscription"]:checked').val();
    
        if (subscription === 'subscription') {
            const product_line = basket_lines.find(`[data-basket-product-id="${product_id}"][data-basket-sub-id="0"]`);

            if (product_line.length > 0) {
                const basket_qty = product_line.find(".cart_action .product_qty .qty_of_prd").val();    
                $('.product_hero .cart_action .product_qty .qty_of_prd').val(basket_qty);
            } else {
                $('.product_hero .cart_action .product_qty .qty_of_prd').val(0);
            }
        }else{
            const sub_id = $('#sub_options').val();    
            const sub_line = basket_lines.find(`[data-basket-product-id="${product_id}"][data-basket-sub-id="${sub_id}"]`);
    
            if (sub_line.length > 0) {
                const basket_qty = sub_line.find(".cart_action .product_qty .qty_of_prd").val();    
                $('.product_hero .cart_action .product_qty .qty_of_prd').val(basket_qty);
            } else {
                $('.product_hero .cart_action .product_qty .qty_of_prd').val(0);
            }
        }
    }); 
    
    // Product add to basket from product card
    $('.js_add_to_cart').on('click', function(e) {
        e.preventDefault();

        if (!signed_in) {
            $('.cart_notification').addClass('open');
            return false;
        }
        
        const product = $(this).closest('.product_card');
        const product_id = $(this).data('product-id');
        const ProductData = {}
        ProductData.product_id = product_id;
        ProductData.sub_id = 0;
        ProductData.product_img = product.find('.product_image .image_container img').attr('src');
        ProductData.product_title = product.find('.product_info .product_title').html();
        ProductData.product_size = product.find('.product_info .product_qty span:first').html();
        ProductData.product_cost = product.find('.product_info .product_price .cost').html();
        ProductData.basket_currency = product.find('.product_info .product_price .currency').html();

        PostAddToBasket(product_id, function () {
            product.addClass('prd_in_cart');
            addProductToCart(ProductData);
            updateBasketCount('increase');
            updateCartSummary(ProductData.product_cost, "increase");
            showToast(`
                <h4>${ProductData.product_title}</h4>
                <p>Product added to cart</p>
            `);
        });
    });
    //Prevent Action on button once added to basket in product card
    $('.js-in_cart').on('click', function(e) {
        e.preventDefault();
    });

    // Product add to basket from product card mini
    $(document).on('click', '.js_add_to_cart_mini', function(e) {
        e.preventDefault();

        if (!signed_in) {
            $('.cart_notification').addClass('open');
            return false;
        }
        
        const product = $(this).closest('.product_card_mini');
        const product_id = $(this).data('product-id');
        const ProductData = {}
        ProductData.product_id = product_id;
        ProductData.sub_id = 0;
        ProductData.product_img = product.find('.product_image .image_wrapper img').attr('src');
        ProductData.product_title = product.find('.product_text .product_title').html();
        ProductData.product_size = product.find('.product_text').data('product-qty');
        ProductData.product_cost = product.find('.product_text .product_cost .cost').html();
        ProductData.basket_currency = product.find('.product_text .product_cost .currency').html();

        PostAddToBasket(product_id, function () {
            product.addClass('prd_in_cart');
            addProductToCart(ProductData);      
            updateBasketCount('increase');
            updateCartSummary(ProductData.product_cost, "increase");
            showToast(`
                <h4>${ProductData.product_title}</h4>
                <p>Product added to cart</p>
            `);
        });
    });
    //Prevent Action on button once added to basket in product card mini
    $('.in_cart_mini').on('click', function(e) {
        e.preventDefault();
    });

    // Baseket product QTY increase 
    $(document).on('click', '.js_increase_product_qty', function(e) { 
        e.preventDefault();

        const product = $(this).closest('.product');
        const product_id = $(this).data('product-id');
        const sub_id = product.attr('data-basket-sub-id');
        const product_cost = Number(product.find('.product_info .product_cost').data('unit-price'));
        const product_qty = product.find(".cart_action .product_qty .qty_of_prd");
        const product_qty_hero = $(".product_details .cart_action .product_qty .qty_of_prd");        
        let currentValue = parseInt(product_qty.val(), 10);
        let currentValueHero = parseInt(product_qty_hero.val(), 10);

        if(currentValue < 99){
            PostAddToBasket(product_id, function () {
                if (!isNaN(currentValue) && currentValue < 99) { 
                    product_qty.val(currentValue + 1); 
                    if(product.parent().hasClass('js-basket-prds')) {
                        $('.cart .cart_sidebar .product_list .product').each(function(){
                            if($(this).data('basket-product-id') === product_id){
                                $(this).find('.cart_action .product_qty input').val(currentValue + 1);
                            }
                        })
                    }
                    if(sub_id === '0' && product_qty_hero.length) product_qty_hero.val(currentValueHero + 1);
                }    

                updateCartSummary(product_cost, "increase");
                
                let newPrice = product_cost * (currentValue + 1);
                newPrice = newPrice.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                product.find('.product_info .product_cost .line_price').html(newPrice);
            }, sub_id > 0 ? sub_id : undefined);
        }
    });

    // Baseket product QTY decrease
    $(document).on('click', '.js_reduce_product_qty', function(e) {
        e.preventDefault();

        const product = $(this).closest('.product');
        const product_id = $(this).data('product-id');
        const sub_id = product.attr('data-basket-sub-id');
        const product_cost = Number(product.find('.product_info .product_cost').data('unit-price'));
        const product_qty = product.find(".cart_action .product_qty .qty_of_prd");
        const product_qty_hero = $(".product_details .cart_action .product_qty .qty_of_prd");
        let currentValue = parseInt(product_qty.val(), 10);
        let currentValueHero = parseInt(product_qty_hero.val(), 10);

        PostRemoveFromBasket(product_id, function () {            
            if (!isNaN(currentValue) && currentValue > 0) { 
                product_qty.val(currentValue - 1);
                if(sub_id === '0' && product_qty_hero.length) product_qty_hero.val(currentValueHero - 1);
                
                updateCartSummary(product_cost, "decrease");

                if(currentValue === 1){
                    product.remove();
                    updateBasketCount('decrease');  
                    productCartStatus(product_id); 
                    
                    if(product.parent().hasClass('js-basket-prds')) {
                        $('.cart .cart_sidebar .product_list .product').each(function(){
                            if($(this).data('basket-product-id') === product_id){
                                $(this).remove();
                            }
                        })
                    }
                }else{
                    let newPrice = product_cost * (currentValue - 1);
                    newPrice = newPrice.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    product.find('.product_info .product_cost .line_price').html(newPrice);
                    
                    if(product.parent().hasClass('js-basket-prds')) {
                        $('.cart .cart_sidebar .product_list .product').each(function(){
                            if($(this).data('basket-product-id') === product_id){
                                $(this).find('.cart_action .product_qty input').val(currentValue - 1);
                            }
                        })
                    }
                }
                
            }
        }, sub_id > 0 ? sub_id : undefined);
    });

    //Product Details Page Hero Block product QTY increase 
    $('.js-increase-prd-qty').on('click', function(e) {
        e.preventDefault();
        const  subscription = $('input[name="subscription"]:checked').val();
        let sub_plan, sub_id;
        if (subscription === 'subscription') {
            sub_plan = $('#data-plan').attr('data-sub-plan');
            sub_id = $('#sub_options').val();
        }

        const product = $(this).closest('.product_hero');
        const product_qty = product.find(".cart_action .product_qty .qty_of_prd");
        let currentValue = parseInt(product_qty.val(), 10);

        const ProductData = getProductData(product);    
        if (subscription === 'subscription') {
            ProductData.sub_id = sub_id;
            ProductData.sub_plan = sub_plan; 
            ProductData.product_cost = $('#sub-discounted-price').html();
        }else{
            ProductData.sub_id = 0;
        }
        const basket_lines = $('.cart .cart_sidebar .middleContent .store .product_list');

        if(currentValue < 99){
            PostAddToBasket(ProductData.product_id, function () {   
                const basket_line = basket_lines.find(`[data-basket-product-id="${ProductData.product_id}"][data-basket-sub-id="0"]`);
                const sub_line = basket_lines.find(`[data-basket-product-id="${ProductData.product_id}"][data-basket-sub-id="${ProductData.sub_id}"]`);                
                
                if (subscription === 'subscription') {
                    if (sub_line.length === 0) {
                        addProductToCart(ProductData);
                        updateBasketCount('increase');
        
                        showToast(`
                            <h4>${ProductData.product_title}</h4>
                            <p>Product added to cart</p>
                        `);
                    } else {
                        const sub_line_qty = sub_line.find(".cart_action .product_qty .qty_of_prd");
                        let current_sub_qty = parseInt(sub_line_qty.val(), 10);
        
                        if (!isNaN(current_sub_qty) && current_sub_qty < 99) {
                            sub_line_qty.val(current_sub_qty + 1);
        
                            updateLinePrice(ProductData, current_sub_qty, sub_line, "increase");
                            updateCartSummary(ProductData.product_cost, "increase");
                        }
        
                        showToast(`
                            <h4>${ProductData.product_title}</h4>
                            <p>Subscription quantity updated</p>
                        `);
                    }
                }else{
                    if (basket_line.length === 0) {
                        addProductToCart(ProductData);
                        updateBasketCount('increase');

                        showToast(`
                            <h4>${ProductData.product_title}</h4>
                            <p>Product added to cart</p>
                        `);
                    }else{
                        const basket_line_qty = basket_line.find(".cart_action .product_qty .qty_of_prd");
                        let current_line_qty =  parseInt(basket_line_qty.val(), 10);
        
                        //increase the value inside the basket
                        if (!isNaN(current_line_qty) && current_line_qty < 99) { 
                            basket_line_qty.val(current_line_qty + 1);
                            
                            updateLinePrice(ProductData, current_line_qty, basket_line, "increase"); 
                            updateCartSummary(ProductData.product_cost, "increase");
                        }

                        showToast(`
                            <h4>${ProductData.product_title}</h4>
                            <p>Product quantity updated</p>
                        `);
                    }
                }        
                //increase the value in the product hero
                if (!isNaN(currentValue) && currentValue < 99) { 
                    product_qty.val(currentValue + 1);
                }
                
            }, subscription === 'subscription' ? sub_id : undefined);
        }
    });

    //Product Details Page Hero Block product QTY decrease
    $('.js-decrease-prd-qty').on('click', function(e) {
        e.preventDefault();
        const  subscription = $('input[name="subscription"]:checked').val();
        let sub_plan, sub_id;
        if (subscription === 'subscription') {
            sub_plan = $('#data-plan').attr('data-sub-plan');
            sub_id = $('#sub_options').val();
        }

        const product = $(this).closest('.product_hero');
        const product_id = product.find('.product_sizes').length > 0 ? product.find('.product_sizes input:checked + label').data('child-id') : $(this).data('product-id');
        const product_qty = product.find(".cart_action .product_qty .qty_of_prd");
        let currentValue = parseInt(product_qty.val(), 10);

        const ProductData = getProductData(product);  
        if (subscription === 'subscription') {
            ProductData.sub_id = sub_id;
            ProductData.sub_plan = sub_plan; 
            ProductData.product_cost = $('#sub-discounted-price').html();
        }else{
            ProductData.sub_id = 0;
        } 
        
        const basket_lines = $('.cart .cart_sidebar .middleContent .store .product_list');

        PostRemoveFromBasket(product_id, function () {       
            const basket_line = basket_lines.find(`[data-basket-product-id="${ProductData.product_id}"][data-basket-sub-id="0"]`);
            const sub_line = basket_lines.find(`[data-basket-product-id="${ProductData.product_id}"][data-basket-sub-id="${ProductData.sub_id}"]`);

            if (!isNaN(currentValue) && currentValue > 0) { 
                product_qty.val(currentValue - 1);

                if (subscription === 'subscription') {
                    if (sub_line.length > 0) {
                        const sub_line_qty = sub_line.find(".cart_action .product_qty .qty_of_prd");
                        let current_sub_qty = parseInt(sub_line_qty.val(), 10);
    
                        if (!isNaN(current_sub_qty) && current_sub_qty > 1) {
                            sub_line_qty.val(current_sub_qty - 1);
                            updateLinePrice(ProductData, current_sub_qty, sub_line, "decrease");
                            updateCartSummary(ProductData.product_cost, "decrease");
                            showToast(`
                                <h4>${ProductData.product_title}</h4>
                                <p>Subscription quantity updated</p>
                            `);
                        } else {
                            sub_line.remove();
                            updateBasketCount('decrease');
                            productCartStatus(product_id);
                            showToast(`
                                <h4>${ProductData.product_title}</h4>
                                <p>Subscription removed from cart</p>
                            `);
                        }
                    }
                }else{
                    if (basket_line.length > 0 && currentValue === 1) {
                        basket_line.remove();  
                        updateBasketCount('decrease');                  
                        productCartStatus(product_id);
                        showToast(`
                            <h4>${ProductData.product_title}</h4>
                            <p>Product removed from cart</p>
                        `);
                    }else{
                        const basket_line_qty = basket_line.find(".cart_action .product_qty .qty_of_prd");
                        let current_line_qty =  parseInt(basket_line_qty.val(), 10);
        
                        //increase the value inside the basket
                        if (!isNaN(current_line_qty) && current_line_qty > 0) { 
                            basket_line_qty.val(current_line_qty - 1);
                            
                            updateLinePrice(ProductData, current_line_qty, basket_line, "decrease");    
                            updateCartSummary(ProductData.product_cost, "decrease");
                            
                        }
                        showToast(`
                            <h4>${ProductData.product_title}</h4>
                            <p>Product quantity updated</p>
                        `);
                    }
                }
                
            }
        }, subscription === 'subscription' ? sub_id : undefined);
    });

    //Product Details page Add to basket 
    $('.js-add-product-to-cart').on('click', function(e) {
        e.preventDefault();
        const  subscription = $('input[name="subscription"]:checked').val();
        let sub_plan, sub_id;
        if (subscription === 'subscription') {
            sub_plan = $('#data-plan').attr('data-sub-plan');
            sub_id = $('#sub_options').val();
        }

        if (!signed_in) {
            $('.cart_notification').addClass('open');
            return false;
        }

        const product = $(this).closest('.product_hero');
        const product_qty = product.find('.right .product_details .cart_action .product_qty .qty_of_prd');
        let currentValue = parseInt(product_qty.val(), 10);

        const ProductData = getProductData(product);  
        if (subscription === 'subscription') {
            ProductData.sub_id = sub_id;
            ProductData.sub_plan = sub_plan; 
            ProductData.product_cost = $('#sub-discounted-price').html();
        }else{
            ProductData.sub_id = 0;
        }
        const basket_lines = $('.cart .cart_sidebar .middleContent .store .product_list');

        PostAddToBasket(ProductData.product_id, function () {
            const basket_line = basket_lines.find(`[data-basket-product-id="${ProductData.product_id}"][data-basket-sub-id="0"]`);
            const sub_line = basket_lines.find(`[data-basket-product-id="${ProductData.product_id}"][data-basket-sub-id="${ProductData.sub_id}"]`);


            if (subscription === 'subscription') {
                if (sub_line.length === 0) {
                    addProductToCart(ProductData);
                    updateBasketCount('increase');
    
                    showToast(`
                        <h4>${ProductData.product_title}</h4>
                        <p>Product added to cart</p>
                    `);
                } else {
                    const sub_line_qty = sub_line.find(".cart_action .product_qty .qty_of_prd");
                    let current_sub_qty = parseInt(sub_line_qty.val(), 10);
    
                    if (!isNaN(current_sub_qty) && current_sub_qty < 99) {
                        sub_line_qty.val(current_sub_qty + 1);
    
                        updateLinePrice(ProductData, current_sub_qty, sub_line, "increase");
                        updateCartSummary(ProductData.product_cost, "increase");
                    }
    
                    showToast(`
                        <h4>${ProductData.product_title}</h4>
                        <p>Subscription quantity updated</p>
                    `);
                }
            }else{
                if (basket_line.length === 0) {
                    addProductToCart(ProductData);
                    updateBasketCount('increase');

                    showToast(`
                        <h4>${ProductData.product_title}</h4>
                        <p>Product added to cart</p>
                    `);
                }else{   
                    const basket_line_qty = basket_line.find(".cart_action .product_qty .qty_of_prd");
                    let current_line_qty =  parseInt(basket_line_qty.val(), 10);

                    //increase the value inside the basket
                    if (!isNaN(current_line_qty) && current_line_qty < 99) { 
                        basket_line_qty.val(current_line_qty + 1);

                        updateLinePrice(ProductData, current_line_qty, basket_line, "increase");                    
                        updateCartSummary(ProductData.product_cost, "increase");
                    }
                    showToast(`
                        <h4>${ProductData.product_title}</h4>
                        <p>Product quantity updated</p>
                    `);
                    
                }
            }

            //increase the value in the product hero
            if (!isNaN(currentValue) && currentValue < 99) { 
                product_qty.val(currentValue + 1);
            }
        }, subscription === 'subscription' ? sub_id : undefined);
    });

    var delivery_slot = new Swiper(".cart_timeslots .slot_days .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        spaceBetween: 10,   
    });


    $('.change_time_slot').on('click', function(e) {
        e.preventDefault();
        $('.cart_timeslots').addClass('open');
    })
    $('.js-close-cart-timeslot').on('click', function(e) {
        e.preventDefault();
        $('.cart_timeslots').removeClass('open');
    })

    const updateTimeSlots = (data) => {
        // Clear previous slots
        $('.cart .cart_timeslots .swiper-wrapper').empty();
        $('.cart .cart_timeslots .slot_times').empty();
          
        // Loop through each day's data
        data.forEach((day, index) => {
            // Add day slot in the swiper
            const daySlot = `
                <div class="swiper-slide day js-toggle-cart-delivery-slots ${index === 0 ? 'active' : ''}" data-day="${day.day_abbr.toLowerCase()}">
                  <span>${day.day_name}</span>
                  <span>${day.day_abbr}</span>
                </div>
                `;
            $('.cart .cart_timeslots .swiper-wrapper').append(daySlot);
          
            // Loop through each time slot for that day
            day.slots.forEach((slot) => {
                const isAvailable = slot.available ? '' : 'unavailable';
                const slotInput = `
                    <div class="time_slot ${isAvailable}" data-day="${day.day_abbr.toLowerCase()}" style="${index === 0 ? 'display: block;' : 'display: none;'}">
                        <input type="radio" name="time_slot" hidden id="${day.day_abbr.toLowerCase()}-${slot.pk}" ${slot.available ? '' : 'disabled'}>
                        <label for="${day.day_abbr.toLowerCase()}-${slot.pk}" data-slot-id="${slot.pk}" data-date="${day.date}">
                        <div class="left">
                            <div class="selector">
                                <div class="circle">
                                    <div class="round"></div>
                                </div>
                            </div>
                            <span>${slot.timeframe}</span>
                        </div>    
                        ${!slot.available ? `<span>Unavailable</span>` : ''}
                        </label>
                    </div>
                    `;
                $('.cart .cart_timeslots .slot_times').append(slotInput);
            });
        });
    };
    //need to update this later
    const timeslotsGet = () => {
        GetTimeSlots(function(data){
            updateTimeSlots(data)
        })
    }
    timeslotsGet();

    $(document).on('click', '.js-toggle-cart-delivery-slots', function() {
        const selector = $(this).data('day');
        $('.cart .cart_timeslots .day').each(function(){
            $(this).removeClass('active');
        })
        $(this).addClass('active');
        $('.cart .cart_timeslots .slot_times .time_slot').each(function(){
            $(this).data('day') === selector ? $(this).show(): $(this).hide();            
        })
    });

    $('.js-set-quick-timeslot').on('click', function() {
        let selectedTimeSlot, selcted_date, selected_slot, slot_value, date_value;

        $('.cart .cart_timeslots').find('.time_slot').each(function(){
            if($(this).find('input').is(':checked')){
                selectedTimeSlot = $(this);
                selcted_date = selectedTimeSlot.find('label').data('date');
                selected_slot = selectedTimeSlot.find('label').data('slot-id');
                slot_value = selectedTimeSlot.find('label span:first').text();
            }
        })
        $('.cart .cart_timeslots .slot_days .day').each(function(){
            $(this).hasClass('active') ? date_value = $(this).find('span:last').html() + ', ' + $(this).find('span:first').html() : null;
        })

        PostCheckoutSetTimeslot(selected_slot, selcted_date, function(){
            $('.cart_timeslots').removeClass('open');
            $('.cart .bottombar .derlivery_slot span').html(`${date_value}, ${slot_value}`);
        })
    });

    $('.js-open-sub-modal').on('click', function(e) {
        $('.confirm-plan-change').addClass('open');
        $('body').addClass('locked');

        const changeMode = $(this).attr('data-change-option');
        $('.js-change-plan').removeClass('js-sub-pause').removeClass('js-sub-end').removeClass('js-sub-resume');
        $('.js-change-plan').addClass(`js-sub-${changeMode}`);
        $('.js-change-plan').html(`${changeMode} Plan`);
        $('.sub-change').html(changeMode);
    });
    
    $('.js-close-sub-popup').on('click', function(e) {
        $('.confirm-plan-change').removeClass('open');
        $('body').removeClass('locked');
    });

    $(document).on('click', '.js-sub-pause', function(e) {
        e.preventDefault();
        const sub_id = $(this).attr('data-sub-id');

        PostSubscriptionPause(sub_id, function(){
            showToast(`
                <h4>Subscription Settings</h4>
                <p>Your Subscription has been paused!</p>
            `);
            setTimeout(() => {
                $('.confirm-plan-change').removeClass('open');
                $('body').removeClass('locked');
                location.reload();
            }, 500);
        })
    });
    
    $(document).on('click', '.js-sub-end', function(e) {
        e.preventDefault();
        const sub_id = $(this).attr('data-sub-id');

        PostSubscriptionEnd(sub_id, function(){
            showToast(`
                <h4>Subscription Settings</h4>
                <p>Your Subscription has been Ended!</p>
            `);
            setTimeout(() => {
                $('.confirm-plan-change').removeClass('open');
                $('body').removeClass('locked');
                location.reload();
            }, 500);
        })
    });
    
    $(document).on('click', '.js-sub-resume', function(e) {
        e.preventDefault();
        const sub_id = $(this).attr('data-sub-id');

        PostSubscriptionResume(sub_id, function(){
            showToast(`
                <h4>Subscription Settings</h4>
                <p>Your Subscription has been resumed!</p>
            `);
            setTimeout(() => {
                $('.confirm-plan-change').removeClass('open');
                $('body').removeClass('locked');
                location.reload();
            }, 500);
        })
    });

})