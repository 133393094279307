$( document ).ready(function() {
    // API Call Requests
    const GetSearchResults = (query_term, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: '/en/api/search/query/?query_term=' + query_term, 
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }

    const addProductToSearch = (data) => {
        let productHTML = `
            <div class="product_card_mini${ data.in_basket ? 'prd_in_cart' : '' }" data-product-id="${data.id}">
                <a href="/en/catalogue/${data.url}">
                    <div class="product_image">
                        <div class="image_wrapper">
                            <img src="${data.image}" alt="${data.title}">            
                        </div>
                    </div>
                    <div class="product_text" data-product-qty="${data.size}">
                        <div class="product_title">${data.title}</div>
                        <div class="product_cost">                 
                                <span class="currency">${data.currency}</span>
                                <span class="cost">${data.cost}</span>
                        </div>
                    </div>
                </a>
                <svg class="in_cart_mini" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="Group_27779" data-name="Group 27779" transform="translate(-3132 10721)">
                        <circle class="circle" data-name="Ellipse 2553" cx="11" cy="11" r="11" transform="translate(3132 -10721)" fill="#13262f"/>
                        <path class="tick" d="M15.2,9.293,8.57,15.92,5.578,12.928,4.293,14.212,8.57,18.489l7.911-7.911Z" transform="translate(3132.613 -10723.891)" fill="#fff"/>
                    </g>
                </svg>
                <svg class="js_add_to_cart_mini" data-product-id="{{ product.id|safe }}" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="Group_27778" data-name="Group 27778" transform="translate(-3126 10720)">
                        <path id="icons8-plus" d="M13,2A11,11,0,1,0,24,13,11,11,0,0,0,13,2Zm5.5,12.1H14.1v4.4H11.9V14.1H7.5V11.9h4.4V7.5h2.2v4.4h4.4Z" transform="translate(3124 -10722)" fill="#13262f"/>
                        <line id="Line_4161" data-name="Line 4161" y2="11" transform="translate(3137 -10714.5)" fill="none" stroke="#fff" stroke-width="2.2"/>
                        <line id="Line_4162" data-name="Line 4162" y2="11" transform="translate(3142.5 -10709) rotate(90)" fill="none" stroke="#fff" stroke-width="2.2"/>
                    </g>
                </svg>
            </div>
        `
        $('.searchBar_wrapper .searchSuggestions .suggested_products').append(productHTML);
    }

    
    //Search bar 
    const searchBar = $('.searchbar > form > input, #header_search_form-desktop input');
    const searchKeyword = $('.searhc-input-val');
    const searchKeywordParent = searchKeyword.parent();
    searchKeywordParent.css('opacity', 0); 
    let isInsideSearchBarWrapper = false;

    if($(window).width() >  1200){
        searchBar.focus(function() {
            $('.searchBar_overlay').addClass('active');
            $('.searchBar_wrapper').addClass('open');
            $('#toast-container .toast').remove();
        });
        searchBar.blur(function() {
            if (!isInsideSearchBarWrapper) {
                $('.searchBar_overlay').removeClass('active');
                $('.searchBar_wrapper').removeClass('open');
            }
        });
        $('.searchBar_wrapper .searchSuggestions').mousedown(function() {
            isInsideSearchBarWrapper = true;
        }).mouseup(function() {
            isInsideSearchBarWrapper = false;
        });
        $(document).mousedown(function(e) {
            if (!$(e.target).closest('.searchBar_wrapper .searchSuggestions, .searchbar > form > input').length) {
                $('.searchBar_overlay').removeClass('active');
                $('.searchBar_wrapper').removeClass('open');
            }
        });
        searchBar.on('input', function() {
            const inputValue = searchBar.val();  
            if (inputValue) {
                searchKeyword.text(inputValue);
                searchKeywordParent.css('opacity', 1); 
            } else {            
                searchKeyword.text('');
                searchKeywordParent.css('opacity', 0); 
            }
        });
    }else{
        searchBar.click(function() {
            $('.searchBar_wrapper').addClass('open');
        });
        $('.js-close-search').click(function() {
            $('.searchBar_wrapper').removeClass('open');
        });
        $('.searchSuggestions .topbar form input').on('input', function() {
            const inputValue = $(this).val();  
            if (inputValue) {
                searchKeyword.text(inputValue);
                searchKeywordParent.css('opacity', 1); 
            } else {            
                searchKeyword.text('');
                searchKeywordParent.css('opacity', 0); 
            }
        });
    }


    const debounce = (func, delay) => {
        let timer;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    
    const debouncedSearch = debounce(function() {
        const queryTerm = searchBar.val().trim();

        if (queryTerm.length >= 3) {
            $('.searchBar_wrapper .searchSuggestions .suggested_products').empty(); 
            GetSearchResults(queryTerm, function(data) {
                const searchResults = data.map(item => ({
                    id: item.object.id,
                    image: item.object.image,
                    title: item.object.title,
                    quantity: item.object.size === 1 ? "single" : `${item.object.size} Pack`,
                    cost: parseFloat(item.object.price),
                    currency: basket_currency,
                    in_basket: item.object.quantity > 0,
                    url: `${item.object.slug}_${item.object.id}`
                }));
                
                const see_all_btn = $('.see_all_prd');
                searchResults.length > 0 ? see_all_btn.show() : see_all_btn.hide();
                see_all_btn.find('> a').attr('href', `/en/search/?q=${queryTerm}`);

                searchResults.forEach(productData => addProductToSearch(productData));
            });
        } else {
            $('.searchBar_wrapper .searchSuggestions .suggested_products').empty(); 
        }
    }, 300);

    searchBar.on('input', debouncedSearch);
})


if($(window).width() >  1200){
    var inputWidth = $('.searchbar > form > input').width();
    var leftVal = $('.searchbar > form > input').offset().left;
    $('.searchBar_wrapper .searchSuggestions').width(inputWidth).css("left", leftVal);
}

$(window).on("load resize",function(e){
    if($(window).width() >  1200){
        var inputWidth = $('.searchbar > form > input').width();
        var leftVal = $('.searchbar > form > input').offset().left;
        $('.searchBar_wrapper .searchSuggestions').width(inputWidth).css("left", leftVal);
    }
});