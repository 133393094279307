$( document ).ready(function() {    

    // API Call Requests
    const PostUpdateUser = (data, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/accounts/update?first_name=' + data?.first_name + '&last_name=' + data?.last_name + '&email=' + data?.email + '&phone_number=' + data?.phone_number,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }
    const GetTimeSlots = (address_id, doneCallback) => {
        $.ajax({
            method: 'GET',
            url: '/en/api/checkout/timeslots/?address_id=' + address_id,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostCheckoutSetAddress = (address_id, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/checkout/address/?address_id=' + address_id,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostCheckoutSetCard = (card_id, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/checkout/card/?card_id=' + card_id,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }
    const PostCheckoutSetTimeslot = (slot_id, date, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/checkout/timeslots/set/?slot_id=' + slot_id + '&date=' + date,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }  
    const PostGiftStatus = (status, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/checkout/gift/status?is_gift=' + status,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }  
    const PostCheckoutGiftNote = (note, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: '/en/api/checkout/gift/notes?note=' + note,
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback);
    }   



    let card_type, card_number, card_expiry, card_id;
    const visa = `<svg xmlns="http://www.w3.org/2000/svg" width="31.504" height="22.503" viewBox="0 0 31.504 22.503">
            <g id="Group_27778" data-name="Group 27778" transform="translate(-649 -564.249)">
                <path id="Path_26072" data-name="Path 26072" d="M34.5,28.5a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V12A3,3,0,0,1,6,9H31.5a3,3,0,0,1,3,3Z" transform="translate(646 555.249)" fill="#1565c0"></path>
                <path id="Path_26073" data-name="Path 26073" d="M13.422,19l-1.97,5.875s-.5-2.485-.55-2.8c-1.121-2.559-2.776-2.416-2.776-2.416l1.95,7.589h2.371L15.726,19ZM15.3,27.251h2.154L18.755,19H16.574ZM30.54,19H28.274l-3.533,8.251h2.139l.441-1.178h2.7l.23,1.178h1.96Zm-2.622,5.5,1.172-3.118L29.7,24.5ZM21.81,21.4c0-.455.374-.793,1.445-.793a3.4,3.4,0,0,1,1.493.506l.35-1.732A6.574,6.574,0,0,0,23.079,19c-2.265,0-3.432,1.083-3.432,2.454,0,2.48,2.985,2.14,2.985,3.414,0,.218-.173.723-1.416.723a4.611,4.611,0,0,1-2.07-.457L18.774,26.8a5.06,5.06,0,0,0,2.338.455c1.544,0,3.687-1.155,3.687-2.814C24.8,22.44,21.81,22.3,21.81,21.4Z" transform="translate(644.719 552.75)" fill="#fff"></path>
                <path id="Path_26074" data-name="Path 26074" d="M10.468,23.5,9.743,19.94a1.331,1.331,0,0,0-1.18-.772H5.233S9.479,20.422,10.468,23.5Z" transform="translate(645.441 552.708)" fill="#ffc107"></path>
            </g>
        </svg>`;
    const mastercard = `<svg xmlns="http://www.w3.org/2000/svg" width="31.504" height="20" viewBox="0 0 31.504 20">
            <g id="icons8-mastercard" transform="translate(-2 -10)">
                <path id="Path_26069" data-name="Path 26069" d="M28.024,10A10,10,0,1,0,38.048,20,10.012,10.012,0,0,0,28.024,10Z" transform="translate(-4.544)" fill="#ff9800"></path>
                <path id="Path_26070" data-name="Path 26070" d="M12.024,10A10,10,0,1,0,22.048,20,10.012,10.012,0,0,0,12.024,10Z" fill="#d50000"></path>
                <path id="Path_26071" data-name="Path 26071" d="M18,20.716a9.969,9.969,0,0,0,4.3,8.2,9.966,9.966,0,0,0,0-16.391A9.969,9.969,0,0,0,18,20.716Z" transform="translate(-4.544 -0.716)" fill="#ff3d00"></path>
            </g>
        </svg>` ; 


    const updateTimeSlots = (data) => {
        // Clear previous slots
        $('#delivery_slot .swiper-wrapper').empty();
        $('#delivery_slot .slot_times').empty();
          
        // Loop through each day's data
        data.forEach((day, index) => {
            // Add day slot in the swiper
            const daySlot = `
                <div class="swiper-slide day js-toggle-delivery-slots ${index === 0 ? 'active' : ''}" data-day="${day.day_abbr.toLowerCase()}">
                  <span>${day.day_name}</span>
                  <span>${day.day_abbr}</span>
                </div>
                `;
            $('#delivery_slot .swiper-wrapper').append(daySlot);
          
            // Loop through each time slot for that day
            day.slots.forEach((slot) => {
                const isAvailable = slot.available ? '' : 'unavailable';
                const slotInput = `
                    <div class="time_slot ${isAvailable}" data-day="${day.day_abbr.toLowerCase()}" style="${index === 0 ? 'display: block;' : 'display: none;'}">
                        <input type="radio" name="time_slot" hidden id="${day.day_abbr.toLowerCase()}-${slot.pk}" ${slot.available ? '' : 'disabled'}>
                        <label for="${day.day_abbr.toLowerCase()}-${slot.pk}" data-slot-id="${slot.pk}" data-date="${day.date}">
                        <div class="left">
                            <div class="selector">
                                <div class="circle">
                                    <div class="round"></div>
                                </div>
                            </div>
                            <span>${slot.timeframe}</span>
                        </div>    
                        ${!slot.available ? `<span>Unavailable</span>` : ''}
                        </label>
                    </div>
                    `;
                $('#delivery_slot .slot_times').append(slotInput);
            });
        });
    };
    //need to update this later
    const timeslotsGet = ( address_id = 1 ) => {
        GetTimeSlots(address_id, function(data){
            updateTimeSlots(data)
        })
    }
    timeslotsGet(1);

    $(document).on('click', '.js-toggle-delivery-slots', function() {
        const selector = $(this).data('day');
        $('#delivery_slot .day').each(function(){
            $(this).removeClass('active');
        })
        $(this).addClass('active');
        $('#delivery_slot .slot_times .time_slot').each(function(){
            $(this).data('day') === selector ? $(this).show(): $(this).hide();            
        })
    });

    $('.change_details.user-details').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#user-details').show();
    });

    //form field validator
    const validateForm = (form) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;    
        let isValid = true; 
    
        // Reset all borders and error message
        form.find('input').css("border", ""); 
        form.find('.error-message').remove();
    
        // Find and highlight empty fields
        const emptyFields = form.find('input[type="text"]').not('#id_phone_number, .iti__search-input').filter(function() {
            return $(this).val().trim() === "";
        });
        if (emptyFields.length > 0) {
            emptyFields.css("border", "1px solid red");
            isValid = false;
        }
    
        // Validate email field
        const emailField = form.find('input[name="email"]');
        if (emailField.length && !emailRegex.test(emailField.val().trim())) {
            emailField.css("border", "1px solid red");
            isValid = false;
        }
    
        // Validate phone number field (intl-tel-input)
        const phoneField = form.find('input[name="phone_number"]');
        if (phoneField.length) {
            const telInst = phoneField.data('intl');
            if (telInst && !telInst.isValidNumber()) {              
                phoneField.css("border", "1px solid red");
                isValid = false;
            }
        }       

        // Validate select fields
        const selectFields = form.find('select');
        selectFields.each(function() {
            const selectedValue = $(this).val();
            if (!selectedValue || selectedValue === "default") {
                $(this).next('.select2-container').css("border", "1px solid red"); 
                isValid = false;
            } else {
                $(this).next('.select2-container').css("border", "");
            }            
        });

        // Validate textarea fields
        const textAreas = form.find('textarea').not('#address_notes');
        textAreas.each(function() {
            const textValue = $(this).val().trim();
            if (!textValue) {
                $(this).css("border", "1px solid red"); 
                isValid = false;
            } else {
                $(this).css("border", ""); 
            }
        });
    
        // Display error message if form is invalid
        if (!isValid) {
            if (!form.find('.error-message').length) {
                showToast(`
                    <h4>Form validation error!</h4>
                    <p>Fill out all required fields correctly.</p>
                `);
            }
        }
    
        return isValid;
    };  
    
    $('.js-save-user-details').on('click', function(e) {
        e.preventDefault();
        const form = $(this).closest('form');
        
        if (validateForm(form)) {
            const data = {
                first_name: $('#first_name').val(),
                last_name: $('#last_name').val(),
                email: $('#email').val(),
                phone_number: $('#phone_number').data('intl').getNumber()
            };
            
            PostUpdateUser(data, function() {
                $('#user-details-default').show();
                $('#user-details').hide();

                $('#user-details-default .top h4').html(data.first_name + ' ' + data.last_name);
                $('#user-details-default p.dont-transform').html(`${data.email} <br> ${data.phone_number}`);

                showToast(`
                    <h4>User Details</h4>
                    <p>Saved successfully.</p>
                `);
            });
        }
    });
    $('.js-close-user-details').on('click', function(e) {
        $('#user-details-default').show();
        $('#user-details').hide();
    })

    
    $('.change_details.user-address').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#user-address').show();
    });
    $('#user-address .Addresses .address').on('click', function() {
        const selected_address = $(this);
        $('#user-address .Addresses .address').each(function(){
            $(this).removeClass('active');
        })
        selected_address.addClass('active');
    });

    $('.js-close-addresses').on('click', function() {        
        $('#user-address-default').show()      
        $('#user-address').hide();
    });
    $('.js-save-address').on('click', function() {
        let selected_address;
        $('#user-address .Addresses').find('.address').each(function() {
            if($(this).find('input').is(':checked')){                 
                selected_address = $(this);
            }
        })
        const address_id = selected_address.find('input').val();
        const address_line1 = selected_address.find('label .details .line1').text();
        const address_line2 = selected_address.find('label .details .line2').text();

        PostCheckoutSetAddress(address_id, function () {
            $('#user-address-default').find('h4').text(address_line1);
            $('#user-address-default').find('p').text(address_line2);

            $('#user-address-default').show()      
            $('#user-address').hide();
            
            //need to update this later
            GetTimeSlots(1, function(data){
                updateTimeSlots(data);
            })
            
        })
    });

    $('.open_address_form').on('click', function() {   
        $('#user-address').hide();
        $('#user-address-form').show();
    });

    $('.js-close-address-form').on('click', function() {   
        $('#user-address').show();
        $('#user-address-form').hide();

        const form = $('.update_delivery_address');
        form.find('input, textarea, .select2-container').css("border", ""); 
        form.find('.error-message').remove();
    });


    
    $('.change_details.delivery_slot').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#delivery_slot').show();
    });
    $('.js-close-timeslots').on('click', function() {
        $('#delivery_slot_default').show();        
        $('#delivery_slot').hide();
    });
    $('.js-save-timeslot').on('click', function() {
        let selectedTimeSlot, selcted_date, selected_slot, slot_value, date_value;

        $('#delivery_slot').find('.time_slot').each(function(){
            if($(this).find('input').is(':checked')){
                selectedTimeSlot = $(this);
                selcted_date = selectedTimeSlot.find('label').data('date');
                selected_slot = selectedTimeSlot.find('label').data('slot-id');
                slot_value = selectedTimeSlot.find('label span:first').text();
            }
        })
        $('.slot_days .day').each(function(){
            $(this).hasClass('active') ? date_value = $(this).find('span:last').html() + ', ' + $(this).find('span:first').html() : null;
        })

        PostCheckoutSetTimeslot(selected_slot, selcted_date, function(){
            $('#delivery_slot_default').show();        
            $('#delivery_slot').hide();

            $('#delivery_slot_default .top h4').html(slot_value);

            $('#delivery_slot_default > p').length === 0 ? $('#delivery_slot_default').append(`<p>${date_value}</p>`) :
                $('#delivery_slot_default > p').html(date_value);
        })
    });


    var delivery_slot = new Swiper("#delivery_slot .swiper", {
        direction: 'horizontal',
        loop: false,       
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        spaceBetween: 10,   
    });   

    
    $('.change_details.payment_method').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#payment_method').show();
    });
    
    $('.js-close-payments').on('click', function() {
        $('#payment_method_default').show();        
        $('#payment_method').hide();
        
        if($(this).hasClass('cards-available')){
            $('#js-add-new-card-form').hide();        
            $('#js-payment-card-list').show();
            $('.js-open_card_form-checkout').parent().show();
        }  
    });
    $('.js-save-payment').on('click', function() {
        $('#payment_method_default').show();        
        $('#payment_method').hide();

        if($(this).hasClass('cards-available')){
            $('#js-add-new-card-form').hide();        
            $('#js-payment-card-list').show();
            $('.js-open_card_form-checkout').parent().show();
        }  

        $('#payment_method').find('.saved_card').each(function() {
            if($(this).find('input').is(':checked')){ 
                
                const card = $(this);
                card_type = card.find('label .left .card_details h4').html();
                card_number = card.find('label .left .card_details p span').html();
                card_expiry = card.find('label .middle p').html()
                let updated_card_number = card_number.replace("XXXX XXXX XXXX ", "Card Ending with ");
                card_id = card.find('label').data('card-id');

                PostCheckoutSetCard(card_id, function () {    
                    const default_card_val = $('#payment_method_default')
                    default_card_val.find('.top h4').html(updated_card_number);
                    default_card_val.find('p span').html(card_type + ' . ' + card_expiry );
                    
                    if (card_type.toLowerCase().includes('visa')) {
                        default_card_val.find('p svg').replaceWith(visa);
                    } else if (card_type.toLowerCase().includes('mastercard')) {
                        default_card_val.find('p svg').replaceWith(mastercard);
                    }
                })
            }
        })
    });

    
    $('.js-open_card_form-checkout').on('click', function() {
        $(this).parent().hide();
        $('#js-add-new-card-form').show();        
        $('#js-payment-card-list').hide();
    });

    $('.change_details.user-giftnote').on('click', function() {
        $(this).closest('.user_default').hide();        
        $('#user-giftnote').show();
    });

    $('.js-swicth-gift-status').on('click', function() {
        const checker = $(this).parent().find('#send_as_gift');

        PostGiftStatus(checker.is(':checked') ? false : true, function(){
            if($('body').hasClass('checkout')) 
                setTimeout(function(){ location.reload();}, 200)
            
            if(checker.is(':checked'))    
                PostCheckoutGiftNote('');
        });
    });

    $('.js-save-giftnote').on('click', function() {
        const giftNote = $('#gift_note').val();
        console.log(giftNote);

        PostCheckoutGiftNote(giftNote, function(){
            $('#user-giftnote-default').show();        
            $('#user-giftnote').hide();
            showToast(`
                <h4>Gift Note</h4>
                <p>Note saved successfully!</p>
            `);
        })
    });
    $('.js-close-giftnote').on('click', function() {
        $('#user-giftnote-default').show();        
        $('#user-giftnote').hide();
    });

})