$(document).ready(function () {    
    // API Call Requests
    const PostProductReview = (product_id, score, review, doneCallback) => {
        $.ajax({
            method: 'POST',
            url: `/en/api/catalogue/review/add?product_id=${product_id}&score=${score}&body=${review}`, 
            headers: {'X-CSRFToken': csrf_token},
        }).done(doneCallback).fail(function(err) {
            console.error("Error:", err);
        });
    }

    $('.js-scroll-to-review').on('click', function() {
        var headerHeight = $('header').outerHeight() + 50;
    
        $('html, body').animate({
            scrollTop: $('#user_review').offset().top - headerHeight
        }, 1000); 
    });

    $(".stars label").hover(
        function() {
            $(this).prevAll().addBack().addClass("highlight");
        },
        function() {
            $(".stars label").removeClass("highlight");
            $(".stars input[type='radio']:checked").each(function() {
                const selectedLabel = $(`label[for='${this.id}']`);
                selectedLabel.prevAll().addBack().addClass("highlight");
            });
        }
    );

    $(".stars input[type='radio']").on("change", function() {
        $(".stars label").removeClass("highlight");

        const selectedLabel = $(`label[for='${this.id}']`);
        selectedLabel.prevAll().addBack().addClass("highlight");
    });

    $('.js-add-product-review').on('click', function() {
        $('.product-review-modal').addClass('open');
        $('body').addClass('locked');

        const product_id = $('#product-id').val(); 
        const score = $('input[name="score"]:checked').val(); 
        const review = $('#review-message').val();

        PostProductReview(product_id, score, review, function(){
            $('.product-review-modal').removeClass('open');
            $('body').removeClass('locked');

            showToast(`
                <h4>Product Review</h4>
                <p>Submitted successfully!</p>
            `);
            setTimeout(() => {
                location.reload()
            }, 2000);
        })
    });
    $('.js-close-review').on('click', function() {
        $('.product-review-modal').removeClass('open');
        $('body').removeClass('locked');
    })
    
})